import React, { Component } from 'react';
import Header from './header/Header';
import About from './about/About';
import Resume from './resume/Resume';
import Testimonials from './testimonials/Testimonials';
import Footer from './footer/Footer';

export default class App extends Component {
    render() {
        return (
            <div className="App">
                <Header />
                <About />
                <Resume />
                <Testimonials />
                <Footer />
            </div>
        );
    };
}
