import React, { Component } from 'react';

export default class Header extends Component {
    render() {
        return (
            <React.Fragment>
                <header id="home">
                    <nav id="nav-wrap">
                        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                        {/* eslint-disable-next-line */}
                        <a className="mobile-btn" href="#" title="Hide navigation">Hide navigation</a>
                        <ul id="nav" className="nav">
                            {/* eslint-disable-next-line */}
                            <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
                            {/* eslint-disable-next-line */}
                            <li><a className="smoothscroll" href="#about">About</a></li>
                            {/* eslint-disable-next-line */}
                            <li><a className="smoothscroll" href="#resume">Resume</a></li>
                            {/* eslint-disable-next-line */}
                            <li><a className="smoothscroll" href="#testimonials">Testimonials</a></li>
                        </ul> {/* end #nav */}
                    </nav> {/* end #nav-wrap */}
                    <div className="row banner">
                        <div className="banner-text">
                            <h1 className="responsive-headline">Rod Oliveira</h1>
                            <h3>
                                <span>
                                    Software Developer with a bachelor’s degree in Computer Engineering.
                                    Earned the IBM Super Learner badge every year from 2019 to 2024, which recognizes
                                    employees who possess a growth mindset and put learning at the forefront of their career.
                                    Let's&nbsp;<a className="smoothscroll" href="#about">start scrolling</a> and learn more&nbsp;
                                    {/* eslint-disable-next-line */}
                                    <a className="smoothscroll" href="#about">about me</a>.
                                </span>
                            </h3>
                            <hr />
                            <ul className="social">
                                <li><a target="_blank" rel="noopener noreferrer" href="http://linkedin.com/in/rodoliveira"><i className="fa fa-linkedin" /></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://github.com/jrodolfo"><i className="fa fa-github" /></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.flickr.com/photos/jrodolfo/sets/72157662674803713"><i className="fa fa-instagram" /></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/jrodolfo"><i className="fa fa-facebook" /></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="http://twitter.com/jrodolfo"><i className="fa fa-twitter" /></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="http://gameknot.com/stats.pl?jrodolfo"><i className="fa fa-dribbble" /></a></li>
                            </ul>
                        </div>
                    </div>
                    <p className="scrolldown">
                        <a className="smoothscroll" href="#about"><i className="icon-down-circle" /></a>
                    </p>
                </header>
            </React.Fragment>
        );
    }
}
