import React, { Component } from 'react';

export default class Testimonials extends Component {
    render() {
        return (
            <React.Fragment>
                {/* Testimonials Section ================================================== */}
                <section id="testimonials">
                    <div className="text-container">
                        <div className="row">
                            <div className="two columns header-col">
                                <h1><span>Coworkers Testimonials</span></h1>
                            </div>
                            <div className="ten columns flex-container">
                                <div className="flexslider">
                                    <ul className="slides">
                                        <li>
                                            <blockquote>
                                                <p>
                                                    I have known Rod since 2010 when he took my graduate course in IT
                                                    Project Management at Rowe School of Business, Dalhousie University.
                                                    I came to know him as a person with excellent knowledge of his field,
                                                    great skills in applying his knowledge in developing demanding
                                                    applications, and fantastic attitude as a team player/leader. His
                                                    ability to connect with people from diverse professional and ethnic
                                                    backgrounds to create a functional team environment is really amazing.
                                                    During his studies in the IT Project Management course, he
                                                    thoroughly impressed me and his classmates.
                                                </p>
                                                <cite>Abdul-Rahim Nasir Ahmad, Professor of Business Strategy/Supply Chain Analytics</cite>
                                            </blockquote>
                                        </li> {/* slide ends */}
                                        <li>
                                            <blockquote>
                                                <p>
                                                    Rod was a key resource to the success of a critical project at CGI.
                                                    In addition to his excellent Java development skills he was able to
                                                    learn new technologies in a very short timeframe during the project.
                                                    This was critical to finish the project successfully. His
                                                    deliverables are high quality. He was an engaged and professional
                                                    team member.
                                                </p>
                                                <cite>Jussara Cacula, PMP - Project Manager at IBM</cite>
                                            </blockquote>
                                        </li> {/* slide ends */}
                                        <li>
                                            <blockquote>
                                                <p>
                                                    I worked with Rod for more than one year on Java based systems for
                                                    the financial sector. He is a dedicated and reliable software
                                                    developer who strives for quality results.<br />
                                                    Holding a range of Java certifications and having more than 8 years
                                                    experience with Java based technologies, Rod is well-versed in the
                                                    full software development life-cycle and familiar with practices
                                                    like requirements analysis, unit testing, clean code, build
                                                    automation, design visualization, technical documentation and peer
                                                    reviews.
                                                </p>
                                                <cite>Oliver Doepner - Software Engineer at IMP Solutions</cite>
                                            </blockquote>
                                        </li> {/* slide ends */}
                                        <li>
                                            <blockquote>
                                                <p>
                                                    Rod was part of my team at MRO. At the time we worked closely for
                                                    more than a year in the development of a brand new application.
                                                    During this time Rod demonstrated tremendous drive, facility in
                                                    learning new technologies, ownership of his work and bias for
                                                    delivering results.
                                                </p>
                                                <cite>Diueine Monteiro - Software Engineer at Google</cite>
                                            </blockquote>
                                        </li> {/* slide ends */}
                                        <li>
                                            <blockquote>
                                                <p>
                                                    Rod is a loyal and dedicated software developer who is enjoyable to
                                                    work with. He was an effective contributor to the team and regularly
                                                    took initiative to learn new supporting technologies and showed a
                                                    willingness to undertake additional work as required.
                                                </p>
                                                <cite>Stephen Greene - Account Executive - Energy Sector at Microsoft</cite>
                                            </blockquote>
                                        </li> {/* slide ends */}
                                        <li>
                                            <blockquote>
                                                <p>
                                                    Rod is a very details oriented person who always followed process and
                                                    procedures exactly. He would never hesitate to ask questions or make
                                                    recommendations when it was necessary. Rod worked well within the
                                                    team and was well liked by everyone.
                                                </p>
                                                <cite>Gary Ogden - Lead Software Engineer at Salesforce</cite>
                                            </blockquote>
                                        </li> {/* slide ends */}
                                        <li>
                                            <blockquote>
                                                <p>
                                                    Rod Oliveira was responsible for the development of DeCS web
                                                    interface, an important system for regional health centers of Latin
                                                    American. Applied and focused, Rod was devoted diligently not only
                                                    for the project launching, but in its maintenance.
                                                </p>
                                                <cite>Rodrigo Ferrari - Research Assistant at University of Cologne</cite>
                                            </blockquote>
                                        </li> {/* slide ends */}
                                        <li>
                                            <blockquote>
                                                <p>
                                                    Rod was a hard working, dedicated student in the MEC program at
                                                    Dalhousie University. It is clear that his strengths are in data
                                                    mining and software engineering. While working with Rod on group
                                                    projects, Rod demonstrated his IT expertise and was always willing
                                                    to share his knowledge with less experienced group members.
                                                </p>
                                                <cite>Kelly Blair - Service Office Manager at Gil-Son Construction</cite>
                                            </blockquote>
                                        </li> {/* slide ends */}
                                        <li>
                                            <blockquote>
                                                <p>
                                                    Rod is a team player and among those few ones who exchange
                                                    information to empower the team. He is meticulous and a strong java
                                                    developer. Given the opportunity, I look forward to working with him
                                                    again.
                                                </p>
                                                <cite>Mousbah Barake - Data Scientist and Optimisation Practitioner at Legato Health Ireland</cite>
                                            </blockquote>
                                        </li> {/* slide ends */}
                                    </ul>
                                </div> {/* div.flexslider ends */}
                            </div> {/* div.flex-container ends */}
                        </div> {/* row ends */}
                    </div>  {/* text-container ends */}
                </section> {/* Testimonials Section End*/}
            </React.Fragment>
        );
    }
}
